import React, { useState } from "react";
import WalletConnection from "./components/WalletConnection";
import {
  FaHome,
  FaExchangeAlt,
  FaInfoCircle,
  FaBars,
  FaTimes,
} from "react-icons/fa";

const EXCHANGE_RATE = 0.0004887;

function App() {
  const [isWalletConnected, setWalletConnected] = useState(false);
  const [userWallet, setUserWallet] = useState(null);
  const [tradeType, setTradeType] = useState("FB-to-BTC");
  const [inputAmount, setInputAmount] = useState("");
  const [outputAmount, setOutputAmount] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  // Dummy receiving wallets
  const fbReceivingWallet =
    "bc1p4hknpflmflnp9s4tkcrp7fz26zhdjrq55j25dndfds605c0yl6xqwnwcr5";
  const btcReceivingWallet = "bc1qjagcmn8824xhmt0egcvvczneflp5guq5ghnt84";

  const handleWalletConnection = (walletAddress) => {
    setWalletConnected(true);
    setUserWallet(walletAddress);
  };

  const handleTradeTypeChange = (e) => {
    setTradeType(e.target.value);
    setInputAmount("");
    setOutputAmount(0);
  };

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setInputAmount(amount);

    if (tradeType === "FB-to-BTC") {
      setOutputAmount((amount * EXCHANGE_RATE).toFixed(8)); // FB -> BTC
    } else {
      setOutputAmount((amount / EXCHANGE_RATE).toFixed(8)); // BTC -> FB
    }
  };

  const handleLogout = () => {
    setWalletConnected(false);
    setUserWallet(null);
  };

  // Toggle mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="bg-black min-h-screen text-gold font-sans flex flex-col justify-between">
      {/* Navbar */}
      <nav className="flex justify-between items-center p-4 shadow-lg bg-black border-b-2 border-gold">
        <h1 className="text-2xl sm:text-3xl font-bold">FB OTC Trading</h1>

        {/* Desktop Menu */}
        <ul className="hidden sm:flex space-x-6">
          <li>
            <a href="/" className="flex items-center hover:text-gray-300">
              <FaHome className="mr-2" />
              Home
            </a>
          </li>
          <li>
            <a href="/trade" className="flex items-center hover:text-gray-300">
              <FaExchangeAlt className="mr-2" />
              Trade
            </a>
          </li>
          <li>
            <a href="/about" className="flex items-center hover:text-gray-300">
              <FaInfoCircle className="mr-2" />
              About
            </a>
          </li>
        </ul>

        {/* Mobile Hamburger Menu */}
        <div className="sm:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="focus:outline-none text-white"
          >
            {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        {/* Mobile Dropdown Menu */}
        {menuOpen && (
          <ul className="sm:hidden flex flex-col space-y-4 mt-4 absolute top-16 left-0 w-full bg-black p-4 border-t-2 border-gold">
            <li>
              <a href="/" className="flex items-center hover:text-gray-300">
                <FaHome className="mr-2" />
                Home
              </a>
            </li>
            <li>
              <a
                href="/trade"
                className="flex items-center hover:text-gray-300"
              >
                <FaExchangeAlt className="mr-2" />
                Trade
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="flex items-center hover:text-gray-300"
              >
                <FaInfoCircle className="mr-2" />
                About
              </a>
            </li>
          </ul>
        )}

        {isWalletConnected && (
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-all"
          >
            Logout
          </button>
        )}
      </nav>

      {/* Main Content */}
      <main className="flex-grow p-4 md:p-8 lg:p-12 flex flex-col items-center justify-center">
        <section className="max-w-3xl w-full text-center mb-8">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">
            Welcome to Fractal BTC OTC Trading Platform
          </h2>
          <p className="mb-4 text-sm sm:text-base md:text-lg">
            Securely trade Fractal Bitcoin (FB) for Bitcoin (BTC) and vice versa
            using Over-the-Counter (OTC) trading. Connect your Unisat wallet,
            specify the trade amount, and complete your transaction within 20
            minutes. All transactions are automated.
          </p>
        </section>

        {/* Wallet Connection */}
        <section className="w-full max-w-xl text-center ">
          {!isWalletConnected ? (
            <>
              <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">
                Connect Your Unisat Wallet
              </h2>
              <WalletConnection onConnect={handleWalletConnection} />
            </>
          ) : (
            <>
              <div className="bg-gray-800 p-4 rounded-lg mb-8">
                <p className="text-sm sm:text-xl">
                  Connected Wallet:{" "}
                  <span className="font-mono text-green-500">{userWallet}</span>
                </p>
              </div>

              {/* Trade Form */}
              <section className="bg-gray-900 p-6 rounded-lg shadow-lg w-full">
                <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-4">
                  Make a Trade
                </h2>

                {/* Trade Type Selection */}
                <div className="mb-4">
                  <label className="block text-lg font-bold mb-2">
                    Select Trade Type:
                  </label>
                  <select
                    value={tradeType}
                    onChange={handleTradeTypeChange}
                    className="w-full p-2 rounded bg-gray-800 text-white"
                  >
                    <option value="FB-to-BTC">
                      Fractal Bitcoin (FB) to Bitcoin (BTC)
                    </option>
                    <option value="BTC-to-FB">
                      Bitcoin (BTC) to Fractal Bitcoin (FB)
                    </option>
                  </select>
                </div>

                {/* Trade Amount Input */}
                <div className="mb-4">
                  <label className="block text-lg font-bold mb-2">
                    {tradeType === "FB-to-BTC"
                      ? "Amount of Fractal Bitcoin (FB):"
                      : "Amount of Bitcoin (BTC):"}
                  </label>
                  <input
                    type="number"
                    value={inputAmount}
                    onChange={handleAmountChange}
                    placeholder={
                      tradeType === "FB-to-BTC"
                        ? "Enter FB amount"
                        : "Enter BTC amount"
                    }
                    className="w-full p-2 rounded bg-gray-800 text-white"
                  />
                </div>

                {/* Display Equivalent Amount */}
                <div className="mb-4">
                  <p className="text-sm font-bold">
                    Equivalent in{" "}
                    {tradeType === "FB-to-BTC"
                      ? "Bitcoin (BTC):"
                      : "Fractal Bitcoin (FB):"}
                  </p>
                  <p className="text-2xl text-green-500 font-mono">
                    {outputAmount} {tradeType === "FB-to-BTC" ? "BTC" : "FB"}
                  </p>
                </div>

                {/* Display Receiving Wallet Address */}
                <div className="mb-4">
                  <p className="text-lg font-bold">
                    Payment Address (for{" "}
                    {tradeType === "FB-to-BTC" ? "BTC" : "FB"}):
                  </p>
                  <p className="font-mono text-lg text-yellow-500">
                    {tradeType === "FB-to-BTC"
                      ? btcReceivingWallet
                      : fbReceivingWallet}
                  </p>
                  <p className="text-sm text-gray-400">
                    Send the equivalent amount to this address within 20
                    minutes.
                  </p>
                </div>
              </section>
            </>
          )}
        </section>
      </main>

      {/* Footer */}
      <footer className="p-4 bg-black border-t-2 border-gold text-center">
        <p>&copy; 2024 FB OTC Platform. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
