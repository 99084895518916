import React, { useState } from "react";

const WalletConnection = ({ onConnect }) => {
  const [loading, setLoading] = useState(false);

  const connectWallet = async () => {
    if (window.unisat) {
      setLoading(true);
      try {
        const accounts = await window.unisat.requestAccounts();
        const address = accounts[0];
        onConnect(address);
        setLoading(false);
      } catch (error) {
        console.error("Connection error:", error);
        setLoading(false);
      }
    } else {
      alert(
        "Unisat wallet is not installed. Please install Unisat wallet extension."
      );
    }
  };

  return (
    <div className="text-center">
      <button
        onClick={connectWallet}
        className={`px-6 py-3 bg-gold text-black font-bold rounded-lg transition-all ${
          loading ? "opacity-50 cursor-not-allowed" : "hover:bg-yellow-500"
        }`}
        disabled={loading}
      >
        {loading ? "Connecting..." : "Connect Unisat Wallet"}
      </button>
      {!window.unisat && (
        <p className="text-red-500 mt-4">
          Unisat wallet not detected. Please install it to connect.
        </p>
      )}
    </div>
  );
};

export default WalletConnection;
